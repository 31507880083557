import { Box, Center, Grid, Group, SimpleGrid, Stack } from '@mantine/core';
import React from 'react';
import { SPACING_LG, SPACING_MD } from '@/config/theme';
import ImageContainer from './_generic/ImageContainer';
import Section from './_generic/Section';

import supporter1 from '../public/images/supporters/1.png';
import supporter2 from '../public/images/supporters/2.png';
import supporter3 from '../public/images/supporters/3.png';
import supporter4 from '../public/images/supporters/4.png';
import supporter5 from '../public/images/supporters/5.png';

const partnerLogoHeight = 70;
const imageMaxWidth = '70%';

const Supporters = ({ isMobile }) => {
  const span_size = isMobile ? 6 : 4;

  return (
    <Section color='white' title='Soutiens'>
      <Grid
        grow
        mt={SPACING_MD}
        // mb={SPACING_MD}
        gutter={(isMobile ? 1 : 2) * SPACING_MD}
      >
        <Grid.Col span={span_size}>
          <a target='_blank' href='https://fondation-janmichalski.com/fr'>
            <Center>
              <ImageContainer
                imageMaxWidth={imageMaxWidth}
                image={supporter3}
                height={70}
                sizes='(max-width: 500px) 25vw, 20vw'
              />
            </Center>
          </a>
        </Grid.Col>
        <Grid.Col span={span_size}>
          <a target='_blank' href='https://prohelvetia.ch/fr/'>
            <Center>
              <ImageContainer
                imageMaxWidth={imageMaxWidth}
                image={supporter2}
                height={70}
                sizes='(max-width: 500px) 25vw, 20vw'
              />
            </Center>
          </a>
        </Grid.Col>
        <Grid.Col span={span_size}>
          <a
            target='_blank'
            href='https://prolitteris.ch/fr/'
            style={{ textAlign: 'center' }}
          >
            <ImageContainer
              imageMaxWidth={imageMaxWidth}
              image={supporter5}
              height={70}
              sizes='(max-width: 500px) 25vw, 20vw'
            />
          </a>
        </Grid.Col>

        <Grid.Col span={span_size}>
          <a target='_blank' href='https://www.ciip.ch/'>
            <Center>
              <ImageContainer
                imageMaxWidth={imageMaxWidth}
                image={supporter4}
                height={70}
                sizes='(max-width: 500px) 25vw, 20vw'
              />
            </Center>
          </a>
        </Grid.Col>

        <Grid.Col span={span_size}>
          <a
            target='_blank'
            href='https://www.oertlistiftung.ch/fr/fonds-frei/'
          >
            <Center>
              <ImageContainer
                imageMaxWidth={imageMaxWidth}
                image={require('../public/images/supporters/logo_frei_fond.png')}
                height={70}
                sizes='(max-width: 500px) 25vw, 20vw'
              />
            </Center>
          </a>
        </Grid.Col>

        <Grid.Col span={span_size}>
          <a target='_blank' href='https://www.agglo-fr.ch/'>
            <ImageContainer
              imageMaxWidth={imageMaxWidth}
              image={require('../public/images/partners/1.png')}
              height={partnerLogoHeight}
              sizes='(max-width: 500px) 25vw, 20vw'
            />
          </a>
        </Grid.Col>

        <Grid.Col span={span_size}>
          <a target='_blank' href='https://www.loro.ch/fr'>
            <ImageContainer
              imageMaxWidth={imageMaxWidth}
              image={require('../public/images/partners/11.jpg')}
              height={partnerLogoHeight}
              sizes='(max-width: 500px) 25vw, 20vw'
            />
          </a>
        </Grid.Col>

        <Grid.Col span={span_size}>
          <a target='_blank' href='https://www.fondation-vm.ch/fr'>
            <ImageContainer
              imageMaxWidth={imageMaxWidth}
              image={require('../public/images/supporters/FVM_Logo_noir_transparent.png')}
              height={partnerLogoHeight}
              sizes='(max-width: 500px) 25vw, 20vw'
            />
          </a>
        </Grid.Col>

        <Grid.Col span={span_size}>
          <a target='_blank' href='https://www.fr.ch/'>
            <Center>
              <ImageContainer
                imageMaxWidth={imageMaxWidth}
                image={supporter1}
                height={70}
                sizes='(max-width: 500px) 25vw, 20vw'
              />
            </Center>
          </a>
        </Grid.Col>

        <Grid.Col span={span_size}>
          <a target='_blank' href='https://www.ville-fribourg.ch/'>
            <ImageContainer
              imageMaxWidth={imageMaxWidth}
              image={require('../public/images/partners/7.png')}
              height={partnerLogoHeight}
              sizes='(max-width: 500px) 25vw, 20vw'
            />
          </a>
        </Grid.Col>
      </Grid>
    </Section>
  );
};

export default Supporters;
