import { Box, Center, Image, Paper, Text, UnstyledButton } from '@mantine/core';
import React from 'react';
import { COLOR_BLACK_SHINY } from '@/config/theme';
import ImageContainer from '../_generic/ImageContainer';

const PublicationLink = ({ data, image, onClick }) => {
  const { title } = data;

  const is_clickable = !!onClick;
  return (
    <Box
      onClick={() => {
        if (onClick) onClick();
      }}
      sx={{
        background: 'transparent',
        textAlign: 'center',
        cursor: is_clickable ? 'pointer' : 'default',
        transition: '0.15s',

        '&:hover': {
          opacity: is_clickable ? 0.8 : 1,
        },
      }}
    >
      <UnstyledButton
        sx={{
          cursor: is_clickable ? 'pointer' : 'default',
          // transition: '0.15s',
          // '&:hover': {
          // 	opacity: 0.8,
          // },
        }}
      >
        <Paper
          shadow='4px 4px 6px 0px rgba(0,0,0,0.5)'
          p='xxs'
          sx={{ width: '110px' }}
        >
          <ImageContainer
            image={
              data?.image?.cover_thumbnail?.sizes?.thumbnail?.url
                ? data?.image.cover_thumbnail.sizes.thumbnail.url
                : image
            }
            height={170}
            fit='cover'
            loader={() => {
              return image_url;
            }}
          />
        </Paper>
      </UnstyledButton>
      <Text
        sx={{ marginTop: 16, textAlign: 'center', color: COLOR_BLACK_SHINY }}
      >
        {title}
      </Text>
    </Box>
  );
};

export default PublicationLink;
