import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import {
  Anchor,
  Box,
  Button,
  CloseButton,
  Container,
  Stack,
  Title,
} from '@mantine/core';

import {
  COLOR_PRIMARY,
  FONT_FAMILY_PRIMARY,
  FONT_FAMILY_SECONDARY,
  SPACING_LG,
  SPACING_MD,
  SPACING_SM,
  SPACING_XS,
} from '@/config/theme';
import axios from 'axios';
import { useRouter } from 'next/router';
import RichText from './_generic/RichText';
import publishedTextAuthors from '@/helpers/publishedTextAuthors';
import { PAYLOAD_URL } from '@/config/env';

const TextModalContent = ({ data, isMobile }) => {
  const [text, setText] = useState(null);

  const router = useRouter();

  useEffect(() => {
    const text_id = router.query.id;
    if (text_id) {
      const fetchText = async () => {
        const res = await axios.get(
          // `http://localhost:3001/api/texts/${text_id}?depth=1`
          `${PAYLOAD_URL}/api/texts/${text_id}?depth=2`
        );

        setText(res.data);
      };

      fetchText();
    }
  }, [router.query.id]);

  const contentWidth =
    data.contentWidth && !data.nowrap
      ? data.contentWidth
      : ['left-centered', 'center'].includes(data.text_align) || !!data.nowrap
      ? 'inherit'
      : '100%';

  return (
    <>
      {text && (
        <>
          <Box pt={SPACING_LG} pb={SPACING_MD}>
            <Title
              order={1}
              sx={{
                textAlign: 'center',
                fontSize: 40,
              }}
            >
              {text.title}
            </Title>
            <Title
              order={3}
              mt={SPACING_XS}
              sx={{
                fontFamily: 'public-sans',
                textAlign: 'center',
              }}
            >
              {publishedTextAuthors(data.authors)}
              <br />
            </Title>
          </Box>
          <Container
            p={isMobile ? SPACING_SM : SPACING_LG}
            mt={SPACING_XS}
            sx={{
              display: 'flex',
              justifyContent: ['left-centered', 'center'].includes(
                text.text_align
              )
                ? 'center'
                : 'flex-start',
              background: '#fff',
              textAlign: text.text_align
                ? text.text_align === 'left-centered'
                  ? 'left'
                  : text.text_align
                : 'left',

              // border: '2px solid red',
              // overflowX: 'visible',
              // width: 300,
              // overflowX: data?.nowrap ? 'auto' : 'hidden',
              // width: ['left-centered', 'center'].includes(text.text_align)
              //   ? 'inherit'
              //   : '100%',
              overflowX: 'auto',
            }}
          >
            <Box
              sx={{
                position: 'relative',
                display:
                  text.text_align === 'left-centered'
                    ? 'inline-block'
                    : 'block',
                whiteSpace: data?.nowrap ? 'nowrap' : 'normal',
                fontFamily: 'Garamond',
                fontSize: 20,
                // width: 'inherit',
                minWidth: contentWidth,
                // minWidth: 100,
                // overflowX: 'hidden',
                // border: '2px solid orange',
              }}
            >
              {text.type === 'html' ? (
                <div dangerouslySetInnerHTML={{ __html: text.html }} />
              ) : (
                <>
                  {text.lexical_html ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: text.lexical_html }}
                    />
                  ) : (
                    <RichText
                      data={text.richtext}
                      paragraphSpacing={text?.paragraphSpacing}
                      indentAtParagraphStart={text.indentAtParagraphStart}
                    />
                  )}

                  {/* {text.richtext['root'] ? (
                    lexicalToHTML(text.richtext)
                  ) : (
                    <RichText
                      data={text.richtext}
                      paragraphSpacing={text?.paragraphSpacing}
                      indentAtParagraphStart={text.indentAtParagraphStart}
                    />
                  )} */}
                </>
              )}
            </Box>
          </Container>
          {text.pdf && (
            <a href={text.pdf.url} download={text.pdf.filename} target='_blank'>
              <Button
                mt={SPACING_SM}
                variant='outline'
                radius='md'
                color='dark'
                sx={{
                  '&:hover': { background: COLOR_PRIMARY },
                }}
              >
                Obtenir le PDF
              </Button>
            </a>
          )}
        </>
      )}
    </>
  );
};

export default TextModalContent;
