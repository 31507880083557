import Link from 'next/link';
import { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Center,
  Group,
  SimpleGrid,
  Stack,
  Title,
} from '@mantine/core';

import { useMediaQuery } from '@mantine/hooks';

import Creation from '../components/Creation';
import Section from '@/components/_generic/Section';
import PublicationButton from '../components/publications/PublicationButton';
import PublishedTextCard from '../components/PublishedTextCard';
import WorkshopThumbnail from '../components/workshops/WorkshopThumbnail';
import Supporters from '../components/Supporters';
import Partners from '../components/Partners';
import ImageContainer from '@/components/_generic/ImageContainer';
import TextsContainer from '@/components/_generic/TextsContainer';
import PageButton from '@/components/_generic/PageButton';
import Agenda from '../components/agenda/Agenda';
import { useRouter } from 'next/router';
import CustomModal from '@/components/_generic/Modal';
import TextModalContent from '@/components/TextModalContent';
import RichText from '@/components/_generic/RichText';

import {
  COLOR_BLACK_SHINY,
  COLOR_SECONDARY,
  COLOR_PRIMARY,
  FONT_COLOR_DEFAULT,
  SPACING_LG,
  SPACING_MD,
  SPACING_SM,
  SPACING_XS,
  COLOR_GREY_LIGHT,
} from '@/config/theme';

import dbConnect from '../lib/dbConnect';
import getIsMobile_server from '@/utility/getIsMobile_server';

import TextModel from '@/models/Text';
import WorkshopsModel from '../models/Workshop';
import WorkshopModalContent from '@/components/workshops/WorkshopModalContent';
import Memberships from '@/components/Memberships';

import fetchAgenda from '@/payload/fetchAgenda';
import fetchHomePage from '@/payload/fetchHomePage';
import PublicationModalContent from '@/components/publications/PublicationModalContent';
import fetchPublications from '@/payload/fetchPublications';
import AgendaEventModalContent from '@/components/agenda/AgendaEventModalContent';
import CTAButton from '@/components/_generic/CTAButton';
import Image from 'next/image';

export async function getServerSideProps(server_context) {
  const isMobile_server = getIsMobile_server(server_context);

  await dbConnect();

  const homePage = await fetchHomePage();

  const mostRecentPublishedText_arr = await TextModel.find()
    .where('visible')
    .equals(true)
    .sort({ week: -1 })
    .limit(1);

  const currentWeekNumber = mostRecentPublishedText_arr[0].week;

  const texts = await TextModel.find({ week: currentWeekNumber })
    .where('visible')
    .equals(true)
    .sort({ week: -1, createdAt: -1, title: 1 })
    .limit(4);

  const publications = await fetchPublications(2);

  const news = await fetchAgenda(3);

  const today = new Date();
  const today_str = today.toISOString();
  const workshopsFuture = await WorkshopsModel.find({
    visible: true,
    when_date: { $gt: today_str },
  })
    .sort({ when_date: 1 })
    .limit(3);
  const workshopsPassed = await WorkshopsModel.find({
    when_date: { $lt: today_str },
    visible: true,
  })
    .sort({
      when_date: -1,
    })
    .limit(3 - workshopsFuture.length);

  let workshops = workshopsFuture;
  if (workshopsFuture.length < 3) {
    workshops = [...workshopsPassed, ...workshopsFuture].sort((a, b) =>
      a.when_date < b.when_date ? -1 : 1
    );
  }

  return {
    props: {
      homePage_data: JSON.stringify(homePage),
      texts_data: JSON.stringify(texts),
      publications_data: JSON.stringify(publications),
      news_data: JSON.stringify(news.docs),
      workshops_data: JSON.stringify(workshops),
      isMobile_server,
    },
  };
}

export default function Home({
  homePage_data,
  texts_data,
  publications_data,
  news_data,
  workshops_data,
  isMobile_server,
}) {
  /* fetched data */

  const homePage = useMemo(() => JSON.parse(homePage_data), [homePage_data]);
  const texts = useMemo(() => JSON.parse(texts_data), [texts_data]);
  const publications = useMemo(
    () => JSON.parse(publications_data),
    [publications_data]
  );
  const news = useMemo(() => JSON.parse(news_data), [news_data]);
  const workshops = useMemo(() => JSON.parse(workshops_data), [workshops_data]);
  const [selectedRessource, setSelectedRessource] = useState(null);

  /* various */

  const router = useRouter();

  /* modal */

  const [modalOpened, setModalOpened] = useState(false);
  const modalBackgroundColor = useMemo(
    () =>
      router.query?.type === 'atelier' &&
      selectedRessource?.when_date < new Date().toISOString().substring(0, 10)
        ? COLOR_GREY_LIGHT
        : router.query?.type === 'publication'
        ? COLOR_PRIMARY
        : COLOR_SECONDARY,
    [router.query?.type, selectedRessource]
  );

  /* client & server responsive */

  const isMobile_client = useMediaQuery('(max-width: 800px)');
  const isMobile =
    isMobile_client !== undefined ? isMobile_client : isMobile_server;

  useEffect(() => {
    if (router.query.type && router.query.id) {
      let ressource = null;
      switch (router.query.type) {
        case 'texte':
          ressource = texts.find((text) => text._id === router.query.id);
          break;
        case 'atelier':
          ressource = workshops.find(
            (workshop) => workshop._id === router.query.id
          );
          break;
      }
      setSelectedRessource(ressource);
      setModalOpened(true);
    } else {
      setModalOpened(false);
    }
  }, [router.query?.type, router.query?.id]);

  const renderModalContent = () => {
    switch (router.query?.type) {
      case 'texte':
        return (
          <TextModalContent data={selectedRessource} isMobile={isMobile} />
        );
      case 'publication':
        return <PublicationModalContent data={null} isMobile={isMobile} />;
      case 'evenement':
        return (
          <AgendaEventModalContent
            eventId={router.query?.id}
            news={news}
            isMobile={isMobile}
          />
        );
      case 'atelier':
        return <WorkshopModalContent data={selectedRessource} />;
    }
  };

  return (
    <>
      <main style={{ width: '100%' }}>
        <Center
          sx={{
            background: 'transparent',
          }}
        >
          <Title
            mt={SPACING_SM}
            order={1}
            sx={{
              fontWeight: 400,
              fontSize: isMobile ? 18 : 24,
              textAlign: 'center',
              color: COLOR_BLACK_SHINY,
            }}
          >
            Revue de relève littéraire
            <Image
              src={require('../public/images/logo.svg')}
              alt="L'Épître"
              style={{
                display: 'block',
                marginTop: SPACING_LG,
                marginBottom: SPACING_LG,
              }}
              unoptimized
            />
          </Title>
        </Center>

        <Section color='secondary' title='Textes de la semaine'>
          <SimpleGrid
            spacing='md'
            mt={SPACING_MD}
            cols={isMobile ? 1 : 2}
            sx={{ width: '100%' }}
          >
            {texts.map((text) => {
              return (
                <PublishedTextCard
                  key={text._id}
                  data={text}
                  color='secondary'
                  onClick={(data) => {
                    router.push(`?type=texte&id=${data._id}`, undefined, {
                      shallow: true,
                    });
                  }}
                  titleSize={30}
                  height={isMobile ? 150 : 282}
                />
              );
            })}
          </SimpleGrid>

          <Center mt={SPACING_MD}>
            <PageButton title='Tous les textes' href='/textes' />
          </Center>
        </Section>

        <SimpleGrid cols={isMobile ? 1 : 2} spacing={0}>
          <Section
            color='primary'
            disableContainer
            style={{
              display: 'flex',
              justifyContent: isMobile ? 'center' : 'flex-end',
            }}
          >
            <Box
              sx={{
                width: !isMobile ? 960 / 2 - SPACING_MD : 'inherit',
                display: 'flex',
                alignItems: isMobile ? 'center' : 'flex-start',
                flexDirection: 'column',
              }}
            >
              <Title order={2} sx={{ color: '#fff' }}>
                ÉCRIRE UN TEXTE
              </Title>
              <SimpleGrid
                cols={isMobile ? 1 : 1}
                mt={SPACING_MD}
                gutter='md'
                sx={{ color: COLOR_SECONDARY }}
              >
                <RichText data={homePage.writeAText} />
              </SimpleGrid>
              <Link href='/ecrire-un-texte'>
                <CTAButton label='Envoyer un texte' mt={SPACING_MD} />
              </Link>
            </Box>
          </Section>

          <Section
            color='white'
            disableContainer
            style={{
              width: !isMobile ? 960 / 2 - SPACING_MD : 'inherit',
            }}
            pl={30}
          >
            <Title order={2} sx={{ textAlign: isMobile ? 'center' : 'left' }}>
              PUBLICATIONS
            </Title>
            <SimpleGrid cols={isMobile ? 2 : 2} mt={SPACING_MD} spacing={0}>
              {publications.map((publication) => {
                return (
                  <PublicationButton
                    key={publication.id}
                    data={publication}
                    onClick={(data) => {
                      router.push(
                        `?type=publication&id=${publication.id}`,
                        undefined,
                        {
                          shallow: true,
                        }
                      );
                    }}
                    title={publication.title}
                    image={publication.cover_thumbnail.sizes.thumbnail.url}
                  />
                );
              })}
              {/* <PublicationButton
								data={{ title: 'Vol. VIII', image: '' }}
								title='L’ÉPÎTRE Vol. VIII'
								image={require('../public/images/publications/vol5-8_front/vol8.jpeg')}
								image_src={`${'/images/publications/vol5-8_front/vol7.png'}`}
							/> */}
              {/* <PublicationButton
								data={{ title: 'Vol. VII', image: '' }}
								title='L’ÉPÎTRE Vol. VII'
								image={require('../public/images/publications/vol5-8_front/vol7.png')}
								image_src={`${'/images/publications/vol5-8_front/vol7.png'}`}
							/> */}
            </SimpleGrid>
            <Center mt={SPACING_MD}>
              <PageButton
                title='Toutes les publications'
                href='/publications'
              />
              {/* <ActionIcon size='lg' sx={{ width: 150, transition: '0.2s' }}>
								<IconDots size={64} />
							</ActionIcon> */}
            </Center>
          </Section>
        </SimpleGrid>

        <Section color='secondary' title='Agenda' isMobile={isMobile}>
          <Agenda news={news} isMobile={isMobile} />
          <Center mt={SPACING_MD}>
            <PageButton title="Tout l'agenda" href='/agenda' />
          </Center>
        </Section>

        <Section title='Créations' color='dark'>
          <SimpleGrid cols={isMobile ? 2 : 2} mt={SPACING_MD}>
            <Link href='/creations?type=sounds'>
              <Creation
                title='Créations sonores'
                image={require('../public/images/creations/1.png')}
                image_src='/images/creations/1.png'
                isMobile={isMobile}
              />
            </Link>
            <Link href='/creations?type=videos'>
              <Creation
                title='Créations vidéos'
                image={require('../public/images/creations/2.png')}
                image_src='/images/creations/2.png'
                isMobile={isMobile}
              />
            </Link>
            <Link href='/creations?type=expositions'>
              <Creation
                title='Expositions'
                image={require('../public/images/creations/3.jpg')}
                image_src='/images/creations/3.png'
                isMobile={isMobile}
              />
            </Link>
            <Link href='/creations?type=scenic-creations'>
              <Creation
                title='Créations scéniques'
                image={require('../public/images/creations/4.png')}
                image_src='/images/creations/4.png'
                isMobile={isMobile}
              />
            </Link>
          </SimpleGrid>
        </Section>

        <Section color='primary' title='Ateliers et résidences'>
          <SimpleGrid cols={isMobile ? 1 : 3} mt={SPACING_MD}>
            {workshops.map((workshop) => (
              <WorkshopThumbnail
                key={workshop._id}
                data={workshop}
                onClick={() => {
                  router.push(`?type=atelier&id=${workshop._id}`, undefined, {
                    shallow: true,
                  });
                }}
              />
            ))}
          </SimpleGrid>
          <Center mt={SPACING_MD}>
            <PageButton
              title='Tous les ateliers'
              href='/ateliers'
              color='#fff'
              sx={{
                '&:hover': {
                  color: '#333',
                  background: '#fff',
                },
              }}
            />
          </Center>
        </Section>

        <Section color='white' title="La revue l'épître">
          <SimpleGrid
            cols={isMobile ? 1 : 2}
            mt={SPACING_MD}
            spacing={SPACING_LG}
          >
            <Stack justify='space-between'>
              <Box sx={{ color: COLOR_PRIMARY, fontSize: 20 }}>
                <RichText data={homePage.lepitre} />
              </Box>

              <Group
                mt={SPACING_MD}
                sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
                spacing={SPACING_XS}
              >
                <Link href='/notre-equipe' shallow>
                  <Button
                    // disabled
                    variant='outline'
                    radius='md'
                    color='dark'
                    sx={{
                      width: 175,
                      '&:hover': { background: COLOR_PRIMARY },
                    }}
                  >
                    Notre équipe
                  </Button>
                </Link>

                <Link href={`mailto:info@lepitre.ch`}>
                  <Button
                    disabled={false}
                    variant='outline'
                    radius='md'
                    color='dark'
                    sx={{
                      width: 175,
                      '&:hover': { background: COLOR_PRIMARY },
                    }}
                  >
                    Nous contacter
                  </Button>
                </Link>
              </Group>
            </Stack>
            <ImageContainer
              image={require('../public/images/pictures/team.jpeg')}
              {...(isMobile ? { height: 300 } : { width: '100%' })}
              fit='cover'
              position='top'
            />
            {/* <Box
							sx={{
								height: 300,
								backgroundImage: "url('/images/pictures/team.png')",
								backgroundSize: 'cover',
								backgroundPosition: '50% 0%',
							}}
						/> */}
          </SimpleGrid>
        </Section>

        <Section
          title='Abonnements & dons'
          color='secondary'
          style={{
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-end',
          }}
        >
          <Memberships isMobile={isMobile} />
        </Section>

        <Supporters isMobile={isMobile} />

        <Partners isMobile={isMobile} />

        <CustomModal
          modalOpened={modalOpened}
          setModalOpened={setModalOpened}
          onClose={() => {
            router.push('/', undefined, {
              shallow: true,
            });
          }}
          background={modalBackgroundColor}
          fontColor={router?.query?.type === 'publication' ? '#fff' : null}
        >
          {renderModalContent()}
        </CustomModal>
      </main>
    </>
  );
}
