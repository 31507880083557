import { SimpleGrid } from '@mantine/core';
import React from 'react';
import { SPACING_MD } from '@/config/theme';
import AgendaEventPreview from './AgendaEventPreview';

const modalDate = new Date('2023-06-23');
const modalDate_str = modalDate.toISOString();

const News = ({ news, isMobile }) => {
  return (
    <>
      <SimpleGrid
        cols={isMobile ? 1 : 3}
        mt={SPACING_MD}
        spacing={32}
        verticalSpacing={64}
      >
        {news.map((item) => {
          return (
            <AgendaEventPreview
              key={item.id}
              news={news}
              data={item}
              isMobile={isMobile}
              linkActive={item?.when_date > modalDate_str}
            />
          );
        })}
      </SimpleGrid>
    </>
  );
};

export default News;
