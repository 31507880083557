import {
  Box,
  Center,
  Image,
  Paper,
  Title,
  UnstyledButton,
} from '@mantine/core';
import React from 'react';
import {
  COLOR_PRIMARY,
  FONT_FAMILY_DEFAULT,
  FONT_FAMILY_PRIMARY,
} from '@/config/theme';
import ImageContainer from './_generic/ImageContainer';

const Creation = ({ image, image_src, title, isMobile }) => {
  return (
    <div>
      <Box
        sx={{
          position: 'relative',
          height: isMobile ? 200 : 310,
          // backgroundImage: `url('${BASE_PATH}${image_src}')`,
          // backgroundPosition: 'center',
          // backgroundRepeat: 'no-repeat',
          // backgroundSize: 'cover',
          overflow: 'hidden',
          // cursor: 'pointer',
          userSelect: 'none',

          '&:hover': {
            '.test': {
              transform: 'scale(120%) translateY(-6%)',
            },
          },
        }}
      >
        <ImageContainer
          image={image}
          height={isMobile ? 200 : 310}
          fit='cover'
          sizes={`(max-width: 800px) 50vw, (max-width: 1400px) 33vw, 25vw`}
        />
        <Title
          className='test'
          mt={8}
          order={3}
          sx={{
            position: 'absolute',
            fontFamily: FONT_FAMILY_PRIMARY,
            fontWeight: 400,
            fontSize: isMobile ? 16 : 22,
            background: COLOR_PRIMARY,
            // background:
            // 	'linear-gradient(180deg, rgba(0,0,0,0) 80%, rgba(0,0,0,0.66) 90%)',
            color: '#EFEBE5',
            // padding: 8,
            // paddingTop: 16,
            // paddingBottom: 16,
            width: '100%',
            height: isMobile ? 40 : 60,
            // textShadow: '0px 0px 5px rgba(0, 0, 0, 1)',
            textAlign: 'center',
            transition: '.15s',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bottom: 0,

            // '&:hover': {
            //   transform: 'scale(130%) translateY(-6%)',
            // },
          }}
        >
          <div>{title}</div>
        </Title>
      </Box>
    </div>
  );
};

export default Creation;
